@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
    font-family: "Oswald";
    src: url("./assets/fonts/Oswald-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Teko";
    src: url("./assets/fonts/Teko-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Teko";
    src: url("./assets/fonts/Teko-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Quicksand";
    src: url("./assets/fonts/Quicksand-Regular.ttf") format("truetype");
    font-weight: normal;
} */

body {
    margin: 0;
    font-family: "Quicksand", sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h5 {
    font-size: clamp(12px, 3.2vw, 16px) !important;
    /* font-size: 13px !important; */
}

td {
    text-align: left;
    padding: 5px 10px;
    border: 1px solid #e1e1e1;
    color: #1c1c1c;
}

.statistic-row {
    font-family: "Roboto", sans-serif;
}

.session-title {
    font-family: "Lobster", cursive;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
    font-size: 1.5rem;
    color: #545454;
}

.session-title b {
    display: block;
    flex: 1;
    height: 2px;
    opacity: 0.1;
    background-color: black;
}

.hvr-underline-from-center::before {
    height: 2px !important;
    background-color: #16a34a !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.icon-banner {
    width: clamp(18px, 4vw, 36px);
    height: clamp(18px, 4vw, 36px);
}

/* .magnifier-image {
    object-fit: cover;
    max-width: 510px;
    max-height: 306px;
}
@media (max-width: 1024px) {
    .magnifier-image {
        max-width: 720px;
        max-height: 432px;
    }
} */

/* .swiper-button-next,
.swiper-button-prev {
    user-select: none;
    --swiper-navigation-size: 24px !important;
    color: #333 !important;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.swiper-button-next {
    right: -20px !important; 
}

.swiper-button-prev {
    left: -20px !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 18px;
    font-weight: bold;
} */

/* .product-info {
    position: relative;
    padding: 8px;
}

.product-name {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 8px;
    font-size: 1rem;
    color: #333;
}

.product-detail {
    font-size: 0.875rem;
    color: #f57923;
    margin-top: 4px;
}

.product-detail.desktop-only {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    z-index: -1;
    color: black;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    text-align: center;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	opacity: 0;
}

.product-card:hover .product-detail.desktop-only {
    z-index: 10;
    bottom: 40px;
    opacity: 1;
}

.mobile-only {
    display: block;
}

@media (min-width: 768px) {
    .mobile-only {
        display: none;
    }
    
    .desktop-only {
        display: block;
    }
} */
